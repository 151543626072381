<template>
  <h5>Brukeradministrasjon</h5>
  <div v-if="loading" class="loader">Laster inn....</div>

  <transition name="fade" mode="out-in">
    <div v-if="activateAlert && message" class="alert" role="alert">
      <b-alert variant="warning" show
        >{{ message }}</b-alert
      >
    </div>
  </transition>

  <div class="table-responsive">
    <table
      id="tableComponent"
      class="table table-light table-striped table-sm"
      style="padding:0px 1px 0px 1px;"
    >
      <thead>
        <tr>          
          <th style="padding:0px 1px 0px 1px;text-align: left" @click="sort('username')">
            &nbsp;<i class="fa-solid fa-sort fa-xs"></i><br />
            <i class="fa-solid fa-envelope"></i>
            <span class="hideOnSmallScreens">&nbsp;E-post</span>
          </th>
          <th style="padding:0px 1px 0px 1px;text-align: left" @click="sort('role')">
            &nbsp;<i class="fa-solid fa-sort fa-xs"></i><br />
            <i class="fa-solid fa-circle-user"></i>
            <span class="hideOnSmallScreens">&nbsp;Rolle</span>
          </th>
          <th style="padding:0px 1px 0px 1px;text-align: left" @click="sort('user_level')">
            &nbsp;<i class="fa-solid fa-sort fa-xs"></i><br />
            <i class="fa fa-level-up" aria-hidden="true"></i>
            <span class="hideOnSmallScreens">&nbsp;Nivå</span>
          </th>
          <th style="padding:0px 1px 0px 1px;text-align: left" @click="sort('tutorial_completed')">
            &nbsp;<i class="fa-solid fa-sort fa-xs"></i><br />
            <i class="fa-solid fa-graduation-cap"></i>
            <span class="hideOnSmallScreens">&nbsp;Tutorial</span>
          </th>
          <th style="padding:0px 1px 0px 1px;text-align: left" @click="sort('confirmed')">
            &nbsp;<i class="fa-solid fa-sort fa-xs"></i><br />
            <i class="fa-solid fa-person-circle-check"></i>
            <span class="hideOnSmallScreens">&nbsp;Bekreftet</span>
          </th>
        </tr>
      </thead>
      <tbody class="smallerOnSmallScreens">
        <tr v-for="(user, index) in users" :key="index">         
          <td style="padding:7px 1px 7px 1px;">
            {{ user["username"] }}
          </td>
          <td style="padding:7px 1px 7px 1px;">
            <div class="form-group">
              <select v-model="user.role">
                <option value="ROLE_USER">BRU</option>
                <option value="ROLE_MOD">MOD</option>
                <option value="ROLE_ADMIN">ADM</option>
              </select>
            </div>
          </td>
          <td style="padding:7px 1px 7px 1px;">
            <div class="form-group">
              <div class="form-group">
                <select v-model="user.user_level">
                  <option value="0">LAV</option>
                  <option value="1">MID</option>
                  <option value="2">HØY</option>
                </select>
              </div>
            </div>
          </td>
          <td style="padding:7px 1px 7px 1px;">
            {{ user["tutorial_completed"] == 1 ? "Ja" : "Nei" }}
          </td>
          <td style="padding:7px 1px 7px 1px;">
            {{ user["confirmed"] == 1 ? "Ja" : "Nei" }}
          </td>
          <td style="padding:7px 1px 7px 1px;">
            <b-button
              type="submit"
              size="sm"
              @click="updateRoleAndLevel(user)"
              style="font-size: 0.8em"
            >
              Oppd.
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Start button for loading in more results-->
    <div
      v-if="this.users.length > 0 && this.perPage <= this.users.length"
      class="col-xs-1 mb-2"
      align="center"
      style="padding-bottom: 5rem"
    >
      <button class="btn btn-secondary" @click="loadMore">
        Last inn flere
      </button>
    </div>
    <!-- End button for loading in more results-->
  </div>
</template>

<script>
import UserDataService from "../../services/user.service";

export default {
  name: "users-list",
  components: {},
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: 10,
      orderby: "username",
      order: "asc",
      rows: 10,
      totalPages: 0,
      message: "",
      users: [],
      userColumns: [],
      count: 0,
      currentMeteor: null,
      currentIndex: -1,
      title: "",
      modalShow: false,
      activateAlert: false,
    };
  },
  methods: {
    convertToLocalDate(dateString) {
      const d = new Date(dateString + " +0000");
      var curr_second = d.getSeconds();
      var curr_minute = d.getMinutes();
      var curr_hour = d.getHours();
      var curr_date = d.getDate();
      var curr_month = d.getMonth() + 1; //Months are zero based
      var curr_year = d.getFullYear();
      return (
        curr_year +
        "-" +
        curr_month.toString().padStart(2, "0") +
        "-" +
        curr_date.toString().padStart(2, "0") +
        " " +
        curr_hour.toString().padStart(2, "0") +
        ":" +
        curr_minute.toString().padStart(2, "0") +
        ":" +
        curr_second.toString().padStart(2, "0")
      );
    },

    sort(orderby) {
      console.log(orderby);
      switch (orderby) {
        case "username":
          if (this.orderby == "username") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "username";
          }
          break;
        case "role":
          if (this.orderby == "role") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "role";
          }
          break;
        case "user_level":
          if (this.orderby == "user_level") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "user_level";
          }
          break;
        case "tutorial_completed":
          if (this.orderby == "tutorial_completed") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "tutorial_completed";
          }
          break;
        case "confirmed":
          if (this.orderby == "confirmed") {
            if (this.order == "asc") {
              this.order = "desc";
            } else {
              this.order = "asc";
            }
          } else {
            this.orderby = "confirmed";
          }
          break;
        default:
          this.orderby = "id";
          this.order = "asc";
      }
      this.retrieveUsers();
    },

    retrieveUsers() {
      this.loading = true;
      UserDataService.getUsersPaginated(
        this.currentPage,
        this.perPage,
        this.orderby,
        this.order
      )
        .then((response) => {
          const { users } = response.data;
          this.userColumns = Object.keys(users[0]);
          this.users = users;
          //this.rows = parseInt(totalItems);
          //this.totalPages = parseInt(totalPages);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
      this.loading = false;
    },

    loadMore() {
      console.log(this.perPage);
      if (this.perPage >= this.users.length + 1) return;
      this.perPage = this.perPage + 10;
      this.retrieveUsers();
    },

    updateRoleAndLevel(user) {
      const userToUpdate = {
        id: user.id,
        role: user.role,
        user_level: user.user_level,
      };
      console.log(userToUpdate);

      UserDataService.updateRoleAndLevel(userToUpdate)
        .then((response) => {
          console.log(response.data);
          this.message = "Brukeren ble oppdatert: " + response.data.message;
          this.activateAlert = true;
        })
        .catch((e) => {
          console.log(e);
          this.message = e.data.message;
          this.activateAlert = true;
        });
    },
  },
  watch: {
    activateAlert(val) {
      if (val) {
        setTimeout(() => (this.activateAlert = false), 3500);
      }
    },
  },
  mounted() {
    this.loading = true;
    this.retrieveUsers();
    this.loading = false;
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media screen and (min-width: 0px) and (max-width: 850px) {
  .hideOnSmallScreens {
    display: none;
  } /* hide it on smaller screen */
}

@media screen and (min-width: 0px) and (max-width: 850px) {
  .smallerOnSmallScreens {
    font-size: 0.9rem;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>